<template>
  <a-card>
    <div style="margin-bottom:20px;">
      <a-button type="primary" @click="addNew">
        添加线路
      </a-button>
    </div>
    <div class="ant-table-wrapper">
      <div
        class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
      >
        <div
          class="ant-table-body"
          style="overflowY:scroll"
        >
          <table class="have-min-width">
            <thead class="ant-table-thead">
              <tr>
                <th>始发站</th>
                <th>终点站</th>
                <th>状态</th>
                <th>站点数</th>
                <th>添加站点</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="item in roadList" :key="item.id">
                <td >{{ item.depotNameStart }}</td>
                <td>{{ item.depotNameEnd }}</td>
                <td>
                  <a-switch checked-children="开放" un-checked-children="关闭" @click="changeBusStatus(item)" :checked="item.isOpen" />
                </td>
                <!-- <td>
                  <a-button type="link" @click="showFrencyList(item)"
                    >班</a-button
                  >
                </td> -->
                <td>
                  <!-- {{ item.depotNum }}个 -->
                  <a-button type="link" @click="getSiteList(item)"
                    >查看站点</a-button
                  >
                </td>
                <td>
                  <a-button @click="addSiteShow(item)" type="primary"
                    >添加站点</a-button
                  >
                </td>
                <td>
                  <a-button @click="editRoad(item)" type="primary"
                    >编辑线路</a-button
                  >
                  <a-button
                    @click="delateRoad(item)"
                    type="danger"
                    style="margin-left:10px"
                    >删除线路</a-button
                  >
                </td>
              </tr>
              <tr v-if="!roadList.length">
                <td class="no-data" colspan="6">暂无线路</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--    添加线路-->
    <a-modal
      :title="editRoadStatus ? '编辑线路' : '添加线路'"
      @ok="addRoadMethod"
      :visible="visible"
      @cancel="visible = false"
      okText="确定"
      >
      <div class="addrole">
        <p class="flex">
          <label for="">始发站</label>
          <a-input
            class="flex1"
            :maxLength="20"
            v-model="addRoad.depotNameStart"
            placeholder="请输入始发站"
          ></a-input>
        </p>
        <p class="flex">
          <label for="">终点站</label>
          <a-input
            class="flex1"
            :maxLength="20"
            v-model="addRoad.depotNameEnd"
            placeholder="请输入终点站"
          ></a-input>
        </p>
      </div>
    </a-modal>

    <!-- 站点列表 -->
    <a-modal
      title="站点列表"
      :visible="showSpot"
      okText="确定"
      cancelText="关闭"
      @cancel="showSpot = false"
      :width="630"
    >
      <table class="my-table">
        <tr>
          <th>序号</th>
          <th>站点名称</th>
          <th>单站点耗时</th>
          <th>初始站到本站耗时</th>
          <th>操作</th>
        </tr>
        <tr
            class="content-style"
            v-for="(item, index) in siteList"
            :key="item.busDepotId"
            :id="item.busDepotId"
        >
            <td class="order">{{ index + 1 }}</td>
            <td style="max-width:200px;line-height:20px;">{{ item.depotName }}</td>
            <td>{{ item.duration }}分钟</td>
            <td>{{item.totalDuration}}分钟</td>
            <td>
              <a-button type="link" @click="editSiteShow(item)">编辑</a-button>
              <span @click="deleteSite(item)" class="delete-btn">删除</span>
            </td>
          </tr>
        <tr class="no-data" v-if="!siteList.length"><td colspan="5">暂无</td>  </tr>
      </table>
      <div slot="footer">
        <a-button @click="showSpot = false">关闭</a-button>
        <!-- <a-button type="danger" @click="setOrder" v-if="!showOrder"
          >调整顺序</a-button
        >
        <a-button type="danger" @click="submitOrder" v-if="showOrder"
          >重置排序</a-button
        > -->
      </div>
    </a-modal>
    <!-- 添加站点 -->
    <a-modal
      :title="editSite ? '编辑站点' : '添加站点'"
      @ok="addSite"
      :visible="showSite"
      @cancel="showSite = false;editSite=false"
      okText="确定"
      class="addSpot"
    >
      <div class="flex">
        <label for="">站点名称：</label>
        <a-input
          class="flex1"
          :maxLength="9"
          v-model="addSpot.depotName"
          placeholder="请输入站点名称"
          @change="changeSite"
        ></a-input>
      </div>
      <div class="flex">
        <div class="flex1">
          <p class="flex" style="margin:0">
            <label for="">站点序号：</label>
            <a-input
              class="flex1"
              :maxLength="20"
              v-model="addSpot.sn"
              placeholder="请输入站点属于第几站"
            ></a-input>
          </p>
        </div>
        <div class="flex1" style="margin-left:20px;">
          <p class="flex" style="margin:0">
            <label for="">预估时间：</label>
            <a-input
              class="flex1"
              :maxLength="20"
              v-model="addSpot.duration"
              placeholder="上站到本站的预估时间"
            ></a-input>
            <span style="margin-left:5px;">分钟</span>
          </p>
        </div>
      </div>
      <p class="flex">
        <label for="">站点位置：</label>
        <a-input
          readonly="readonly"
          class="flex1"
          :maxLength="20"
          v-model="addSpot.longitude"
          placeholder="经度"
        ></a-input>
        <span class="distance">,</span>
        <a-input
          readonly="readonly"
          class="flex1"
          style="margin-right:60rpx;"
          :maxLength="20"
          v-model="addSpot.latitude"
          placeholder="纬度"
        ></a-input>
      </p>
      <div
        id="container"
        style="width:100%;height:300px;border:solid 1px rgba(0,0,0,0.3)"
      ></div>
    </a-modal>
    <!-- 班次列表 -->
    <a-modal
      title="班次编辑"
      :visible="showFrequency"
      okText="确定"
      cancelText="关闭"
      @cancel="showFrequency = false"
      
      >
      <div>
        <div class="title-style flex">
          <span class="order">序号</span>
          <span class="name">发车时间</span>
          <span class="time flex1">结束时间</span>
          <span class="action" v-if="editFrency">操作</span>
        </div>
        <div>
          <div
            class="content-style flex"
            v-for="(item, index) in frequencyList"
            :key="item.id"
            :id="item.id"
          >
            <span class="order">{{ index + 1 }}</span>
            <span class="name">
              <div v-if="!editFrency">{{ item.startTime }}</div>
              <div v-else>
                <a-input style="width:70px;" v-model="item.startTime"></a-input>
              </div>
            </span>
            <span class="time flex1">
              <div v-if="!editFrency">{{ item.endTime }}</div>
              <div v-else>
                <a-input style="width:70px;" v-model="item.endTime"></a-input>
              </div>
            </span>
            <span class="action" v-if="editFrency">
              <a-button @click="deleteFrency(index)">删除</a-button>
            </span>
          </div>
        </div>
        <div class="no-data" v-if="!frequencyList.length">暂无</div>
        <div class="frency-list" v-if="editFrency">
          <a-button type="primary" class="newFrency" @click="newFrency">
            添加
          </a-button>
        </div>
      </div>
      <div slot="footer">
        <a-button @click="showFrequency = false;editFrency = false">关闭</a-button>
        <a-button type="danger" @click="editFrency = true" v-if="!editFrency"
          >
          编辑
          </a-button
        >
        <a-button type="danger" @click="saveFrequencyList" v-if="editFrency"
          >保存</a-button
        >
      </div>
    </a-modal>
  </a-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      addRoad: {},
      editRoadStatus: false,
      showSpot: false,
      showSite: false,
      visible: false,
      filter: {
        // cdStatus: "2",
        // pageNum: 1,
        // pageSize: this.pageSize
      },
      roadList:[],
      action: {
        add: false
      },
      siteList: [],
      addSpot: {
        busLineId: "",
        depotName: "",
        longitude: "",
        latitude: "",
        sn: ""
      },
      editSite: false,
      center: [108.938844, 34.376316],
      showOrder: false,
      newOrder: [],
      subOrder: {},
      showFrequency: false,
      frequencyList: [],
      editFrency: false,
      frencyId: ""
    };
  },
  methods: {
    //修改班车状态
    changeBusStatus(item){
      this.$post(this.$url.线路状态, {
        id: item.id,
        isOpen: !item.isOpen
      }).then(() => {
        this.$tips('设置成功','班车状态更新成功！','success')
        this.getRoadList();
      });
    },
    //班次操作
    showFrencyList(item) {
      this.frencyId = item.id;
      this.showFrequency = true;
      this.getFrequencyList();
    },
    deleteFrency(index) {
      this.frequencyList.splice(index, 1);
    },
    newFrency() {
      this.frequencyList.push({
        startTime: "",
        endTime: ""
      });
    },
    saveFrequencyList() {
      let list = [];
      this.frequencyList.forEach(item => {
        if (item.startTime) {
          list.push({
            startTime: item.startTime,
            endTime: item.endTime
          });
        }
      });
      this.$post(this.$url.班次编辑, {
        busLineId: this.frencyId,
        listBusTime: list
      }).then(() => {
        this.editFrency = false;
        this.showFrequency = false;
        this.frencyId = "";
        this.getRoadList();
      });
    },
    getFrequencyList() {
      this.$post(this.$url.班次列表, {
        busLineId: this.frencyId
      }).then(data => {
        this.frequencyList = data.data.list;
      });
    },
    //线路操作
    addRoadMethod() {
      if (!this.addRoad.depotNameStart) {
        this.$warning({
          title: "警告",
          content: "请添加始发站"
        });
        return;
      }
      if (!this.addRoad.depotNameEnd) {
        this.$warning({
          title: "警告",
          content: "请添加终点站"
        });
        return;
      }
      if (this.editRoadStatus) {
        this.editRoadMethod();
        return;
      }
      this.$post(this.$url.添加线路, this.addRoad).then(() => {
        this.$tips('操作成功','线路添加成功！','success')
        this.getRoadList();
        this.visible = false;
      });
    },
    getRoadList() {
      this.$post(this.$url.线路列表, {}).then(data => {
        this.roadList = data.data.list;
      });
    },
    editRoad(item) {
      this.editRoadStatus = true;
      this.visible = true;
      this.addRoad = Object.assign(item);
    },
    editRoadMethod() {
      this.$post(this.$url.线路编辑, this.addRoad).then(() => {
        this.$tips('操作成功','线路编辑成功！','success')
        this.getRoadList();
        this.visible = false;
      });
    },
    addNew() {
      this.editRoadStatus = false
      this.addRoad = {};
      this.visible = true;
      this.editSite = false;
      this.addSpot = {
        busLineId: "",
        depotName: "",
        longitude: "",
        latitude: "",
        sn: ""
      }
    },
    delateRoad(item) {
      this.$confirm({
        title: "删除警告",
        content: "线路删除时对应的班次站点同时被移除，是否确认删除线路?",
        onOk: () => {
          this.$post(this.$url.删除线路, {
            id: item.id
          }).then(() => {
            this.$tips('删除成功','线路删除成功！','success')
            this.getRoadList();
          });
        }
      });
    },
    //站点操作
    getSiteList(item) {
      this.subOrder.busLineId = item.id;
      this.$post(this.$url.站点列表, {
        busLineId: item.id
      }).then(data => {
        this.showSpot = true;
        this.siteList = data.data.list;
      });
    },
    addSiteShow(item) {
      this.showSite = true;
      this.addSpot.busLineId = item.id;
      this.$nextTick(() => {
        this.drawMap();
      });
    },
    addSite() {
      if (!this.addSpot.depotName) {
        this.$warning({
          title: "警告",
          content: "请添加站点名称"
        });
        return;
      }
      if (!this.addSpot.duration) {
        this.$warning({
          title: "警告",
          content: "请添加预计时长"
        });
        return;
      }
      if (this.editSite) {
        this.editSiteMethod();
        return;
      }
      if (this.addSpot.sn == "") {
        this.addSpot.sn = 0;
      }
      this.$post(this.$url.添加站点, this.addSpot).then(() => {
        this.showSpot = false;
        this.showSite = false;
        this.getRoadList();
        this.$success({
          okText: "确定",
          title: "成功",
          content: "站点添加成功！",
          onOk() {}
        });
      });
    },
    deleteSite(item) {
      this.$confirm({
        title: "删除警告",
        content: "站点移除之后已预约自动失效，是否确认删除站点?",
        onOk: () => {
          this.$post(this.$url.删除站点, {
            busLineId: item.busLineId,
            busDepotId: item.busDepotId
          }).then(() => {
            this.getSiteList({
              id: item.busLineId
            });
            this.getRoadList();
            this.$tips('操作成功','站点删除成功！','success')
          });
        }
      });
    },
    editSiteShow(item) {
      this.editSite = true;
      this.showSite = true;
      this.showSpot = false;
      this.addSpot = Object.assign(item);
      this.center = [item.longitude, item.latitude];
      this.$nextTick(() => {
        this.drawMap();
      });
    },
    editSiteMethod() {
      this.$post(this.$url.编辑站点, this.addSpot).then(() => {
        this.showSite = false;
        this.$tips('操作成功','站点编辑成功！','success')
        this.getRoadList();
        this.getSiteList({
          id: this.addSpot.busLineId
        });
      });
    },
    //站点排序
    setOrder() {
      let _this = this;
      this.showOrder = true;
      this.$nextTick(() => {
        $("#sortable").sortable({
          cursor: "pointer",
          revert: true,
          start: function() {
            return;
          },
          stop: function() {
            var arr = $("#sortable").sortable("toArray");
            _this.newOrder = arr;
          }
        });
        $("#sortable").disableSelection();
      });
    },
    submitOrder() {
      this.subOrder.busDepotOrderVoList = [];
      this.newOrder.forEach((item, index) => {
        this.subOrder.busDepotOrderVoList.push({
          busDepotId: item,
          sn: index + 1
        });
      });
      this.$post(this.$url.站点排序, this.subOrder).then(() => {
        this.showOrder = false;
      });
    },
  
    changeSite() {
      this.getPositionXY();
    },

    drawMap() {
      let _this = this;
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: _this.center,
        zoom: 13
      });
      var marker = new AMap.Marker({
        position: map.getCenter(),
        icon:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        offset: new AMap.Pixel(-13, -30),
        // 设置是否可以拖拽
        draggable: true,
        cursor: "move",
        // 设置拖拽效果
        raiseOnDrag: true
      });
      marker.on("dragend", function(e) {
        _this.addSpot.latitude = marker.getPosition().lat;
        _this.addSpot.longitude = marker.getPosition().lng;
      });
      marker.setMap(map);
    },
    /***********************高德地图反解析请求**********************/
    getPositionXY() {
      var map = new AMap.Map("container", {
        resizeEnable: true
      });
      var _this = this;
      var geocoder;
      function geoCode() {
        if (!geocoder) {
          geocoder = new AMap.Geocoder({
            city: "陕西省西安市" //城市设为西安，默认：“陕西西安”
          });
        }
        var address = "陕西省西安市" + _this.addSpot.depotName;
        geocoder.getLocation(address, function(status, result) {
          if (status === "complete" && result.geocodes.length) {
            var lnglat = result.geocodes[0].location;
            _this.addSpot.longitude = lnglat["lng"];
            _this.addSpot.latitude = lnglat["lat"];
            _this.center = [lnglat["lng"], lnglat["lat"]];
            _this.drawMap();
          } else {
            /*_this.$alert('请选择位置','提示',{
              type:'error'
            });*/
            //_this.stopCot = false;
            return;
          }
        });
      }
      geoCode();
    }
  },
  watch: {},
  mounted() {
    this.getRoadList();
  }
};
</script>
<style scoped>
.no-data {
  line-height: 100px;
  text-align: center;
}
.frency-list {
  padding-bottom: 25px;
}
.newFrency {
  margin-top: 10px;
  float: right;
}
.title-style {
  background: #95b8e7;
  color: #fff;
  text-align: center;
}
.content-style {
  height: 32px;
  line-height: 32px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
}
.order {
  width: 60px;
}
.name {
  width: 270px;
}
.time {
  width: 90px;
}
.action {
  width: 150px;
}
.content-style .action {
  border-right: none;
}
.addSpot .flex {
  margin-bottom: 15px;
  line-height:30px;
}
.addrole label {
  display: inline-block;
  width: 90px;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
  line-height:30px;
}
.second-nav span {
  display: block;
}
</style>
